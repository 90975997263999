import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplePayButton from "./ApplePayButton";
import GooglePayButton from "./GooglePayButton";
import {
  initialState,
  CARD_ELEMENT_OPTIONS,
  monthlyInitialState,
} from "./constants";
import AddressForm from "./AddressForm";
import ContactForm from "./ContactForm";
import BillingForm from "./BillingForm";
import { useLocation } from "react-router-dom";
import createIntentPayment from "../services/tickets_payment_intent";
import createOtherIntentPayment from "../services/tickets_other_pay_payment_intent";
import { calculateTotalAmount } from "../services/paymentUtils";
import {
  handleCountryChange as handleCountryChangeUtil,
  handleBillingCountryChange as handleBillingCountryChangeUtil,
  tooltip,
  formatAsCurrency,
} from "../services/formUtils";
import TicketNameForm from "./TicketNameForm";
import CoverFeesSection from "./CoverFeesSection";

const BuyAuctionAccessForm = ({
  updateIsLoading,
  updateHasDonated,
  updateAmount,
  togglePayFees,
  payFees,
  updateFirstName,
  ticketOptions,
  updateTicketOption,
  selectedTicketOption,
  updateUsername,
  selectedTicketQuantityOption,
  ticketQuantityOptions,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const currentURL = location.pathname;
  const isMonthly = currentURL.includes("/monthly");

  const [formData, setFormData] = useState(
    isMonthly ? monthlyInitialState : initialState
  );

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);

  const [titlePrefix, setTitlePrefix] = useState("");

  const getAmount = (selectedTicketQuantityOption, selectedTicketOption) => {
    return selectedTicketQuantityOption.value * selectedTicketOption.cost;
  };

  useEffect(() => {
    const isTest = window.location.hostname.includes('test.');

    if (isTest) {
      setTitlePrefix(<> [Test]</> );
    }

    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: "CA",
      currency: "cad",
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: "Donation to Big Sisters",
        amount: 1000,
      },
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
        setApplePay(result.applePay);
        setGooglePay(result.googlePay);
      }
    });

    pr.on("paymentmethod", async (e) => {
      await createOtherIntentPayment(
        stripe,
        updateFirstName,
        getAmount(selectedTicketQuantityOption, selectedTicketOption),
        payFees,
        e,
        toast,
        updateHasDonated,
        formData,
        selectedTicketQuantityOption.value,
        selectedTicketOption.value,
        updateUsername
      );
    });
  }, [
    stripe,
    elements,
    payFees,
    updateFirstName,
    selectedTicketQuantityOption,
    selectedTicketOption,
    updateUsername,
  ]);

  const updatePaymentRequestInstance = async (event) => {
    paymentRequest.update({
      country: "CA",
      currency: "cad",
      total: {
        label: "Purchase Access to Big Sisters' Auction Site",
        amount:
          calculateTotalAmount(
            getAmount(selectedTicketQuantityOption, selectedTicketOption),
            payFees,
            formData
          ) * 100,
      },
    });
  };

  const toggleValue = (e, label) => {
    console.log(e);
    console.log(label);

    setFormData({
      ...formData,
      [label]: !formData[label],
    });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "amount") {
      updateAmount(event.target.value);
    } else {
      setFormData({
        ...formData,
        [event.target.id]: event.target.value,
      });
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    updateIsLoading(true);

    if (!stripe || !elements) {
      toast.error("Something went wrong. Please refresh the page.");
      updateIsLoading(false);
      return;
    }
    const cardElement = elements.getElement(CardElement);

    // Verify phone number
    let phoneNumber = formData.phoneNumber.replace(/\D/g, ""); // Extract digits

    if (phoneNumber.length === 11 && phoneNumber.startsWith("1")) {
      phoneNumber = phoneNumber.slice(1); // Remove leading '1'
    }

    if (phoneNumber.length !== 10) {
      toast.error(
        "Please enter a valid North American phone number in the format XXX-XXX-XXXX"
      );
      updateIsLoading(false);
      return;
    }

    await createIntentPayment(
      stripe,
      cardElement,
      updateFirstName,
      getAmount(selectedTicketQuantityOption, selectedTicketOption),
      payFees,
      formData,
      updateHasDonated,
      toast,
      setFormData,
      updateIsLoading,
      selectedTicketQuantityOption.value,
      selectedTicketOption.value,
      updateUsername
    );
  };

  const handleCountryChange = (selectedOption) => {
    handleCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingCountryChange = (selectedOption) => {
    handleBillingCountryChangeUtil(selectedOption, formData, setFormData);
  };

  const handleBillingProvinceChange = (selectedOption) => {
    setFormData({ ...formData, billingSelectedProvince: selectedOption });
  };

  const handleProvinceChange = (selectedOption) => {
    setFormData({ ...formData, selectedProvince: selectedOption });
  };

  const handleRealButtonClick = () => {
    updatePaymentRequestInstance();
    paymentRequest.show();
  };

  return (
    <>
      <div className="form-box">
        <form onSubmit={handleSubmit}>
          <h3 style={{ color: "#2dccd3" }}>
            Soirée 2024: Gain Auction Access{titlePrefix}
          </h3>
          <p style={{ color: "#232323" }}>
            Your generous donations support our programs. Please fill in your
            information to gain free access to the site. Your credit card will
            be securely stored to enable bids on our auction site.
          </p>


          <div className="row  text-light">
            <ContactForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            {applePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="ApplePay">Register by Apple Pay</label>
                  <div className="input-group">
                    <ApplePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            {googlePay && (
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="GooglePay">Register by Google Pay</label>
                  <div className="input-group">
                    <GooglePayButton handleClick={handleRealButtonClick} />
                  </div>
                </div>
              </div>
            )}

            <TicketNameForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
              creditCardSectionTitle={
                applePay || googlePay
                  ? "Or Credit Card Payment"
                  : "Credit Card Payment"
              }
            />

            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="cardNumber">
                  Register by Credit Card <span className="required">*</span>
                </label>
                <div className="input-group">
                  <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </div>

            <AddressForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <BillingForm
              formData={formData}
              handleInputChange={handleInputChange}
              handleProvinceChange={handleProvinceChange}
              toggleValue={toggleValue}
              handleCountryChange={handleCountryChange}
              handleBillingProvinceChange={handleBillingProvinceChange}
              handleBillingCountryChange={handleBillingCountryChange}
            />

            <button className="btn btn-theme effect btn-md" type="submit">
              Gain Access Now
            </button>

            <div className="question">
              <div className="icon">
                <i className="fas fa-phone"></i>
              </div>

              <div className="info">
                <h5 style={{ color: "rgb(45, 204, 211)" }}>
                  Have any questions about access to your Soirée 2024
                  auction site?
                </h5>
                <span style={{ color: "#232323" }}>
                  Call Now: 604.873.4525 ext 108
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BuyAuctionAccessForm;