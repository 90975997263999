import React from 'react';
import Select from 'react-select'; // Import Select from react-select
import { DROPDOWN_STYLE, countryOptions } from './constants'; // Import DROPDOWN_STYLE
import SingleChoiceDropdown from './SingleChoiceDropdown';


class AddressForm extends React.Component {
  render() {
    const {
      formData,
      handleInputChange,
      toggleValue,
      creditCardSectionTitle,
    } = this.props; // Destructure props

    const lowerCaseHref = window.location.href.toLowerCase();
    const isVirtual = lowerCaseHref.includes('virtual');

    return (
      <>
          <div className="col-md-12" style={{ textAlign: "center", marginTop: '1rem', marginBottom: '1rem' }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", 
              paddingLeft: '10px', paddingRight:'10px', color: "#54585a", backgroundColor: "#efefef" }}>
                  <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
                  <span style={{ fontSize: "1.25rem", marginRight: "10px", marginLeft: "10px" }}>{creditCardSectionTitle ? creditCardSectionTitle : "Or Donate by Credit Card"}</span>
                  <hr style={{ borderTop: "1px solid #54585a", flex: "1" }} />
              </div>
          </div>


          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="firstName">Donor's First Name{' '}<span className="required">*</span></label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="Your First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="lastName">Donor's Last Name{' '}<span className="required">*</span></label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Your Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </div>


          <SingleChoiceDropdown 
                      value={formData.organization}
                      toggleValue={(e) => toggleValue(e, "organization")}
                      label="Is this donation on behalf of an organization?"
                      identifier="organization"
                      outerClass="col-md-12"
                      isRequired={true}
                />

          {formData.organization && (
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="organizationName">Organization Name{' '}<span className="required">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="organizationName"
                  placeholder="Organization Name"
                  value={formData.organizationName}
                  onChange={handleInputChange}
                  required={formData.organization}
                />
              </div>
            </div>
          )}

          
      </>
    );
  }
}

export default AddressForm;
