import React from "react";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { formData, handleInputChange } = this.props; // Destructure props
    return (


<>


        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="phoneNumber">
              Phone Number Login <span className="required">*</span>
            </label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              placeholder="Used to Login"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="email">
              Email Login <span className="required">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Used to Login"
              value={formData.email}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ContactForm;