import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
    tooltip
  } from "../services/formUtils";

class CoverFeesSection extends Component {
  render() {
    const { payFees, togglePayFees, selectedTicketQuantityOption, selectedTicketOption, formData, calculateTotalAmount, getAmount, formatAsCurrency } = this.props;

    return (
      <div className="row text-light">
        <div className="col-md-12">
          <div className="form-group">
            <div className="d-flex flex-wrap align-items-center">
              <label
                style={{ display: "flex", alignItems: "center" }}
                className="coverFeeOption"
              >
                <input
                  type="checkbox"
                  onChange={togglePayFees}
                  checked={payFees}
                  style={{ accentColor: "#2dccd3" }} // Adjust marginRight as needed
                />
                &nbsp;&nbsp;I will generously cover the&nbsp;
                <span style={{ fontWeight: "bold" }}>transaction fees</span>
                .&nbsp;
                <OverlayTrigger placement="right" overlay={tooltip}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{ color: "#2dccd3" }}
                  />
                </OverlayTrigger>
              </label>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            {payFees &&
              getAmount(selectedTicketQuantityOption, selectedTicketOption) > 0 && (
                <>
                  <p style={{ color: "black" }}>
                    Your payment will come to{" "}
                    {formatAsCurrency(
                      calculateTotalAmount(
                        getAmount(
                          selectedTicketQuantityOption,
                          selectedTicketOption
                        ),
                        payFees,
                        formData
                      )
                    )}
                    .
                  </p>
                </>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default CoverFeesSection;